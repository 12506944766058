@use '@/styles/utils/mixins.scss' as *;

.articleContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .articleContentContainer {
    max-width: calc(1040px + 3.75rem);
    width: 100%;
    margin: 0 auto;
    padding: 0 1.875rem;
    font-size: 1.125rem;
    font-weight: normal;
    @include tab() {
      font-size: 1rem;
    }
  }

  .articleContentBrochureCard {
    background-color: $primary;
    border-radius: 1.25rem;
    padding: 3.75rem 2.5rem;
    display: flex;
    align-items: center;
    gap: 3.75rem;
    color: $white;

    @include tab() {
      padding: 2.5rem 1.875rem;
      gap: 2.5rem;
    }
    @include mob() {
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
      flex-direction: column;
    }

    .articleContentBrochureImageContainer {
      max-width: 460px;
      display: flex;
      align-items: center;
      flex: 0 0 auto;

      .cardImage {
        max-width: 100%;
        height: auto;
        border-radius: 1.25rem;
      }
    }
    .articleContentBrochureContentContainer {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }
    .articleContentBrochureContent {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      @include mob() {
        gap: 2.5rem;
      }
    }
    .description {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.525rem;
    }
    .ctaCardBtn {
      width: fit-content;
      color: $white !important;

      @include mob() {
        width: 100%;
      }
    }
  }
  .documentsLists {
    max-width: calc(1040px + 3.75rem);
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
  }
  .documentItem {
    &Box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.75rem;
      width: 100%;
      border-radius: 1.25rem;
      padding: 1.25rem 1.875rem;
      background-color: $neutral02;

      @include tab() {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &Title {
      font-size: 1rem;
      font-weight: bold;
      color: $primary;
      margin-bottom: 0;
    }
    &DownloadSection {
      display: flex;
      align-items: center;
      gap: 1.25rem;
    }
    &DownloadLink {
      font-size: 1rem;
      font-weight: 500;
      color: $dark;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
}
